@import './variables';

.confirmationContainer {
  box-shadow: $defaultShadow;
  border-radius: 16px;
  overflow: hidden;
  &.is-QUOTE {
    & .booking-summary__container {
      padding-top: 0;
    }
    & .booking-summary__travelers-summary {
      display: none;
    }
  }
  &__image {
    max-height: 200px;
    width: 100%;
    object-fit: cover;

    &__text {
      position: absolute;
      bottom: 0;
      left: 0.5rem;
      color: $white;
      font-size: 3rem;
      max-width: 50%;
    }
  }

  &__success {
    background-color: $green;
    padding: 20px;
    display: flex;

    &__headline {
      font-size: 2rem;
      font-weight: 500;
      color: $secondary;

      &__image {
        font-size: 2.75rem;
        margin-top: -5px;
      }
    }

    &__bookingNumber {
      color: $black;

      &__description {
        font-size: 1.25rem;
      }

      &__number {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}

.confirmationHeadline {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.1;

  @include medium {
    font-size: 39px;
  }
}

.confirmationDescription {
  font-size: 14px;
  color: #202d40;
}

.confirmationBookingnumberDescription {
  font-size: 26px;
  font-weight: bold;
}

.confirmationBookingnumber {
  font-size: 26px;
  color: $primary;
  font-weight: bold;
}

.confirmationPrint {
  padding: 16px;
}
