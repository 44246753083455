.offerContainer {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.offer-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  & > .offerTopRight {
    color: $white;
    font-weight: bold;
    font-size: inherit;
    padding-right: 8px;
  }

  & > .offerTopLeft {
    color: $white;
    font-weight: bold;
    font-size: inherit;
    padding-right: 8px;
    padding-left: 4px;
    padding-top: 6px;
  }
}

.offer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  & > .offerBottomRight {
    color: $white;
    font-size: inherit;
    text-align: right;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 2px;
    flex-shrink: 0;
    flex-basis: auto;

    & .from__text {
      padding-left: 10px;
      font-size: 14px;
    }

    & .price__without__discount {
      padding-left: 5px;
      white-space: nowrap;
      display: inline-block;
      font-size: 14px;

      & .iso__price__value {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }

  & > .offerBottomLeft {
    color: $white;
    font-size: inherit;
    max-width: 70%;
    display: flex;
    align-items: end;
    padding-right: 8px;
    flex-grow: 1;
    word-break: break-all;
  }
}

.offer__hotelLogo-container {
  width: 30%;
  height: auto;
  bottom: 0;
  left: 0;
  position: absolute;
}

.offer__hotelLogo {
  min-height: unset !important;
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.offerPrice {
  display: flex;
  height: 40px;
  width: 100%;
  border-radius: 24px;
  background-color: $secondary;
  color: $priceColor;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  white-space: nowrap;
  position: relative;
}

.offer__tags {
  display: flex;
  flex-wrap: wrap;

  span {
    width: fit-content;
    background: $accentColor;
    margin: 0 0 5px 5px;
    padding: 0 5px;
    border-radius: 10px;
  }
}

.offer-carousel-layout-row {
  .new__offer__container {
    margin: 5px;
  }
}

.smallOffer {
  .new__offer__container {
    img {
      height: 100px !important;
    }
  }
}

.new__offer__container {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
  @include medium {
    padding-bottom: 10px;
  }
  .offer-top {
    top: 8px;
    left: 4px;
    right: 4px;
  }
  img {
    width: 100%;
    height: 280px;
    border-radius: 8px;
    object-fit: cover;
  }

  .price__container {
    .finale__price {
      color: $priceColor;
      font-size: 28px;
      padding-left: 10px;
      white-space: nowrap;
      display: inline-block;

      & .iso__price__value {
        font-weight: 400;
      }
    }

    .from__text {
      padding-left: 10px;
      font-size: 14px;
    }

    .price__without__discount {
      padding-left: 5px;
      white-space: nowrap;
      display: inline-block;
      font-size: 14px;

      & .iso__price__value {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }

  .per__person__price {
    font-size: 12px;
    margin-top: -10px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    & > div {
      position: relative;
    }

    & h3 {
      font-weight: 400;
    }
  }

  &__image {
    flex: 0 1 auto;
  }

  .loading__container {
    min-height: 40px;
  }
}

.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.4);
}
.carousel-indicators {
  margin-bottom: 0px;
}
.discount__price {
  background-color: $priceColor;
  color: $white;
  font-size: 0.875rem;
  padding: 2px 7px;
  border-radius: 4px;
  white-space: nowrap;
  display: inline-block;
  & .iso__price__value {
    font-weight: 400;
  }
}
