.iso__imageProgressBar {
  margin-bottom: 0;
  max-width: 95vw;
  overflow-x: scroll;
  padding-bottom: 8px !important;
  @include small {
    overflow-x: visible;
    max-height: none;
  }

  &__stepperItem {
    font-size: 12px;
    margin-bottom: 0 !important;

    @include medium {
      font-size: 14px;
    }
  }

  &__stepperItem {
    flex-direction: row;
    justify-content: center;
    border-bottom: #e3ebf1 5px solid;
    margin-bottom: 50px;

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }

    &--completed {
      border-bottom: $primary 5px solid;
    }

    &--active {
      border-bottom: $primary 5px solid;
    }

    &__stepName {
      font-weight: 500;
      height: 30px;
      line-height: 25px;
    }

    &__stepImage {
      background: transparent;
      border: none;
      height: 30px;

      &--completed {
        color: $primary;
      }

      &--active {
        color: $primary;
      }
    }
  }
}
