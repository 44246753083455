$table-font-size: 90%;
.iso__faq__accordion-content .fischer-fares {
  & .title {
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: $primary;
    border-radius: 4px 4px 0px 0px;
    color: $white;
  }
  & .row > .col:first-child > div {
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & .absolute {
        font-size: $table-font-size;
        padding: 0.5rem;
        margin-top: 0.5rem;
      }
    }
    height: 100%;
  }

  & .col {
    margin-bottom: 4px;
    & > div {
      border: 2px solid $primary;
      padding: 0;
      margin: 2px;
      border-radius: 10px;
      height: 100%;
    }
    & ul {
      font-size: $table-font-size;
      padding-left: 1.15rem;
      padding-right: 0.25rem;
    }
    & ul > li {
      margin-top: 1rem;
      position: relative;
    }
    & ul > li::before {
      content: '-';
      position: absolute;
      left: -0.85rem;
      top: 0;
      margin: auto;
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      color: $primary;
      margin-top: -0.5rem;
    }
    & ul > li::marker {
      color: transparent;
    }
  }
  & .col-xl {
    padding: 0;
  }
  & .row {
    margin: 0;
    margin-top: 1rem;
  }
}
#fischer-fare .iso__faq__accordion-content,
#extra-services .iso__faq__accordion-content,
#assistance .iso__faq__accordion-content,
#bag-policy .iso__faq__accordion-content {
  padding: 0;
  & > div {
    padding: 0.5rem 0.4rem 2rem 0.3rem;
  }
}

.iso__faq__accordion-content .extra-services {
  & > div {
    border: 2px solid $primary;
    border-radius: 8px 8px 0px 0px;
    & .header {
      text-align: center;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      font-weight: $font-weight-bold;
      color: $white;
    }
    & .header:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    & .content > div:nth-child(even) {
      background-color: #e4ecef;
      //   background-color: #ebe1ef;
    }
    & .content > div {
      display: flex;
      align-items: center;
      padding: 1rem 0.5rem;
      font-size: $table-font-size;
      & > div:nth-of-type(1) {
        width: 50%;
        display: inline-block;
        @include medium {
          width: 22%;
        }
      }
      & > div:nth-of-type(2) {
        width: 50%;
        display: inline-block;
        text-align: center;
        padding: 5px;
        @include medium {
          width: 18%;
        }
      }
      & > div:nth-of-type(3) {
        width: 100%;
        display: inline-block;
        @include medium {
          width: 60%;
        }
      }
    }
  }
}
.iso__faq__accordion-content .fischer-baggage-policy {
  & > div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  & > div > div {
    padding-top: 2rem;
    display: inline-block;
    margin: auto;
    position: relative;
    & > div:first-child {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      justify-content: space-around;
      font-size: $table-font-size;
      & > span {
        text-align: center;
      }
    }
  }
  & img {
    width: 100%;
  }
}

.iso__faq__accordion-content .not-permitted-items {
  & .row {
    margin-bottom: 1.5rem;
  }
}

.iso__faq__accordion-content .assistance {
  & .row {
    margin-bottom: 1.5rem;
    font-size: $table-font-size + 2%;
    word-break: break-word;
  }
}
