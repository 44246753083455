@import './Vars';
@import './cssVars';
@import './utils/mediaQueries';
@import './Faq.scss';

.btn:not(.iso__date-range-input, .iso__dropdown__toggle, .iso__summaryPopover-button) {
  letter-spacing: 0;
}

body {
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: scroll;
  background-position-x: 500px;
  background-position-y: 500px;
  background-color: $white;
}

.btn {
  font-weight: 700;

  &.btn-secondary {
    color: $primary;
    background-color: transparent;
    border: 2px solid $primary;

    &:hover {
      color: $primaryColorContrast;
    }
  }

  .best-price-package-details {
    &.btn-primary,
    &.btn-secondary {
      min-height: 45px;
    }
  }
}

.form-control {
  border-radius: 2px;
}

// alerts
.alert-warning,
.alert-danger {
  background-color: $red;
  border-color: $red;
  color: $white;
}

h2 {
  font-weight: 600;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

// override reboot.scss
b,
strong {
  font-weight: 700;
}

.form-group.required label:after {
  color: $red;
}

.alert-success {
  background-color: $green;
  border-color: $green;
  color: $black;
}

.alert-info {
  background-color: $info;
  border-color: $info;
  color: $white;
}

.iso__dropdown__toggle {
  text-transform: none;
}

.iso__price__value {
  font-weight: 700;
}

// chevron
.fa-chevron-down,
.fa-chevron-up {
  color: $primary;
}

a.overviewLink {
  color: $primary;
}

// calendar
.react-datepicker {
  font-family: $font-family-base;
  border-radius: 0;
  color: $gray-700;

  &__navigation {
    background: none;
    border: unset;
    cursor: pointer;
    height: 15px;
    line-height: 1rem;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 25px;
    width: 15px;
    z-index: 1;
  }

  &__month-container {
    padding: 1rem;
  }

  &__day-name {
    margin: 0;
    line-height: 2.2rem;
    width: 38px;
    height: 38px;
    font-size: 14px;
    color: $gray-700;
  }

  &__day:hover {
    background-color: $gray-200;
    border-radius: 0;
  }

  &__day {
    margin-left: -1px;
    margin-bottom: -1px;
    margin-right: 0;
    margin-top: 0;
    border-radius: 0;
    line-height: 2.2rem;
    border: 1px solid $gray-200;
    width: 38px;
    height: 38px;

    &--disabled {
      color: $gray-200;
    }

    &--today {
      background-color: white;
      color: $gray-700;
    }

    &--today:hover {
      background-color: $gray-300;
      color: $gray-700;
    }

    &--selected {
      color: $white !important;
      background-color: $primary !important;
    }

    &--selecting-range-start:not(.react-datepicker__day--today) {
      background-color: $primary !important;
    }

    &--selecting-range-start,
    &--in-range,
    &--in-range:active {
      color: initial;
      background-color: initial;
    }

    .react-datepicker__day--keyboard-selected,
    &--keyboard-selected:not(.react-datepicker__day--today),
    &--keyboard-selected:active:not(.react-datepicker__day--today) {
      background-color: $primary !important;
    }

    &--in-selecting-range:not(.react-datepicker__day--today) {
      background-color: #17a2b8;
    }

    &--in-selecting-range:hover {
      background-color: lighten($primary, 15%);
    }
  }

  &__current-month {
    font-size: 18px;
    color: #484848;
    font-weight: $font-weight-bold;
  }

  &__header {
    background: none;
    border-bottom: 0;
  }
}

// filter
.iso__filter__resetButton {
  text-transform: uppercase;
  border-bottom: 1px solid $primary;
  font-weight: 700;
  font-size: 0.75rem;
}

.iso__filterRange__values {
  text-align: center;
}

.iso__baseLayoutFilter__caret {
  color: $primary;
  font-size: 0.75rem;
}

.iso__filterRange__track,
.card-header {
  background: $gray-300;
}

.react-select__indicator {
  color: $primary !important;
}

// filter
.iso__filterRange__thumb:after {
  box-shadow: none;
}

.iso__flightSearchResultDetails__button {
  padding-right: 1rem;
}

.iso__flightSearchResultDetails__button,
.iso__baseLayoutFilter__headline {
  color: $primary;
}

.search-tabs .card {
  //box-shadow: 0px 2px 3px #48484a54;
  filter: drop-shadow(0px 2px 3px #48484a54);
  border-radius: 0.25rem;
  color: $gray-700;
}

.iso__selectedContainer {
  border-color: $green !important;

  &__icon {
    color: $green;
  }

  &__iconContainer {
    top: 0;
  }
}

.stopIndicator {
  background-color: $red;
}

.plane-icon {
  color: $gray-500;
}

.stopIndicator {
  border: 1px solid $red;
}

.ibe-tabs__left,
.ibe-tabs__right {
  height: 40px;
}

.ibe-tabs__left::after,
.ibe-tabs__right::after {
  border-color: white !important;
  position: initial !important;
}

.ibe-bg-search {
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: $defaultShadow;

  &__withLogo {
    margin-top: -14rem;
  }
}

.iso__nav__tabs-container {
  cursor: pointer;
}

.iso__termsandconditions__content {
  & a {
    color: $primary;
  }
}

.iso__service-search .select__control {
  height: 38px;
}

.iso__service-search {
  background-color: $white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: $defaultShadow;

  .iso__date-range-input {
    .fa-calendar-alt {
      display: none;
    }

    border-color: $secondary !important;
  }

  &__button {
    font-weight: unset;
  }

  &__icon {
    color: $primary;
  }

  & .geounit-checkbox,
  .productSelect-item {
    input {
      border-radius: $btn-border-radius;
      border-color: $secondary;

      &:hover {
        border-color: darken($secondary, 10%);
      }
    }

    label:before {
      border-color: $secondary;
    }

    button {
      color: $primary;
    }
  }
}

.iso__service-search__column-container .btn-outline-primary {
  color: $primary;
}

#paxSelection .iso__dropdown__toggle.btn {
  border: 1px solid $secondary;
  font-weight: unset;

  &:hover {
    border-color: darken($secondary, 10%);
    background-color: transparent;
  }
}

.iso__date-range-input.custom-button:hover {
  border-color: darken($secondary, 10%) !important;
}

.iso__price-list-item-layout {
  @media (min-width: map-get($grid-breakpoints, 'xs')) {
    font-size: xx-large;
  }
  @media (min-width: map-get($grid-breakpoints, 'md')) {
    font-size: x-large;
  }
}

.searchTabs {
  &--intro {
    background: rgba(255, 255, 255, 0.8);
  }

  label {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.nav-tabs {
  border: 0;
}

.iso__nav__tabs-container {
  .nav-tabs .nav-link {
    border-bottom: 5px solid $gray;
    color: $black;
  }

  .nav-link.active,
  .nav-link.active:hover {
    background-color: transparent;
    border-bottom: 5px solid var(--primary);
    color: $primary;
  }

  .nav-link:hover {
    background-color: transparent;
    border-bottom: 5px solid var(--secondary);
  }

  .tab-content {
    padding: 1rem;
    color: $input-color;
  }

  .tab-notes {
    white-space: pre-line;
  }
}

// checkout
.iso__summaryItem {
  .card-footer {
    background-color: $gray-300;
  }

  .iso__nav__tabs-container {
    .nav-tabs {
      border-bottom: 1px solid #cccccc;
    }

    .nav-link {
      color: $black;
      font-weight: 700;
    }

    .tab-pane {
      .container {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.iso__summaryWrapper .h5 {
  display: flex;
  justify-content: flex-end;

  & b {
    margin-left: 5px;
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
  .customer-display__container {
    width: 30%;
  }
}

.iso__traveler__data,
.checkoutAddressData_form {
  background: $gray-300;
  padding: 1.25rem;

  .form__form-builder__container {
    background: $gray-300;
  }

  &__headline {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .form__error {
    margin-top: 0.25rem;
  }

  .form__error:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: text-top;
    margin-right: 3px;
    background: url('data:image/svg+xml;utf8,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22exclamation-triangle%22%20class%3D%22svg-inline--fa%20fa-exclamation-triangle%20fa-w-18%20mr-1%22%20role%3D%22img%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20576%20512%22%3E%3Cpath%20fill%3D%22%23cd1719%22%20d%3D%22M569.517%20440.013C587.975%20472.007%20564.806%20512%20527.94%20512H48.054c-36.937%200-59.999-40.055-41.577-71.987L246.423%2023.985c18.467-32.009%2064.72-31.951%2083.154%200l239.94%20416.028zM288%20354c-25.405%200-46%2020.595-46%2046s20.595%2046%2046%2046%2046-20.595%2046-46-20.595-46-46-46zm-43.673-165.346l7.418%20136c.347%206.364%205.609%2011.346%2011.982%2011.346h48.546c6.373%200%2011.635-4.982%2011.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884%200-12.356%205.78-11.981%2012.654z%22%3E%3C/path%3E%3C/svg%3E')
      no-repeat bottom left;
  }

  .form__error__top {
    color: $black;
  }

  .input--error {
    margin-bottom: 0.5rem;

    .form-control {
      border-color: $red;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23cd1719%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23cd1719%27 stroke=%27none%27/%3e%3c/svg%3e');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .select__control {
      border-color: $red;
    }
  }
}

.checkoutAddressData_form {
  background: $gray-300;
  padding: 1.25rem;

  &__payer__label {
    font-weight: 700;
  }

  .is-invalid .select__control {
    border-color: $red;
  }
}

.summary__totalPrice > span > span {
  text-align: left;
  font-weight: 700;
}

.iso__travellerForm__heading,
.iso__summaryWrapper .collapseHeader,
.checkoutAddressData__headline,
.payment-options h4,
.iso__agency-reference-container .collapseHeader {
  border-bottom: 3px solid $gray-300;
  position: relative;

  &:before {
    position: absolute;
    background: $primary;
    height: 3px;
    content: '';
    width: 15%;
    bottom: -3px;
    left: 0;
  }
}

.iso__staticContentDisplay__header::before {
  background: linear-gradient(to left, #e4ecef 85%, var(--primary) 15%);
}

.background {
  padding: 70px 1.5rem 0;

  @include medium {
    padding: 70px 0 0 30px;
  }
}

.logo {
  width: 150px;

  @include medium {
    position: absolute;
    top: 26%;
    left: 2%;
  }
}

.traveller-headline {
  font-size: 22px;
  text-transform: capitalize;
}

.customer-display__value,
.customer-display__label {
  padding: 8px;
}

.traveller-display {
  width: 100%;
}

.traveller-details {
  margin-bottom: 2rem;
}

.infantBanner {
  border: 0;
  max-width: max-content;
  background: transparent;
  position: absolute;
  bottom: 0;
  margin: 2rem;
}

.banner {
  background-color: #ffe5e5;
}

.navButton {
  width: inherit;
  margin-bottom: 0.5rem;
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  .navButton {
    width: auto;
    margin-bottom: 0;
  }
}

.iso__ssr-modal-dialog {
  @include large {
    max-width: $modal-lg;
  }
}

.customer-care {
  & > a {
    text-decoration: underline;
  }

  ul {
    padding-left: 0;
    list-style: none;

    & > li {
      position: relative;
      padding-left: 2rem;
      margin-bottom: 0.4rem;
    }

    & > li > a::before {
      content: '';
      position: absolute;
      height: 1.4rem;
      width: 1.4rem;
      border: 2px solid #71acc2;
      left: 0;
    }
  }
}

.payment-options h4 {
  margin-bottom: 1rem;
}

.iso__agency-reference-container .checkoutAddressData__headline {
  display: none;
}

@media (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
}

@import './Footer';
@import '../../components/linkList/linkList.module.scss';
@import '../../components/featureList/FeatureList.module.scss';
@import '../../components/teaser/Teaser.module.scss';
@import '../../components/booking/BookingDetails.module.scss';

.row {
  .mgnlEditorBar {
    @extend .col;
    @extend .col-12;
  }
}

.confirmationImage + img {
  height: auto;
}

.horizontal-spread {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.max-width {
  padding-bottom: 2rem;
  max-width: 92rem;
  margin: 0 auto;
  position: relative;
}

.page {
  &__center {
    flex: 1 1 auto;
  }

  &__bottom {
    margin-top: 0;
    @include medium {
      margin-top: min(8%, 145px);
    }
  }

  &__blank {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }
}

.iso__service-search {
  &__date-range {
    height: 38px;
    padding-top: 7px;
    font-weight: 600;
  }

  label {
    font-weight: 600;
    margin-bottom: 0;
    @include xlarge {
      margin-bottom: 0.5rem;
    }

    svg {
      color: $primary;
      margin-right: 10px;
      width: 18px;
    }
  }
}

.form-control {
  background-color: $white !important;
}

.collapsed-box {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.select__dropdown-indicator {
  color: $primary;
}

.form__error {
  color: $red;
}

.modal {
  overflow-y: auto;
}

.pax-room-selection__button {
  color: $black !important;

  &:hover {
    background-color: $white !important;
    border-color: $secondary !important;
  }
}

.iso__custom__product__select__input {
  display: block;
}

.iso__custom__product__select__input > div:first-child {
  display: block;
}

.iso__custom__product__select__input > div:first-child > span:first-child {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iso__serviceList__filterContainer__filters {
  strong {
    font-weight: lighter;
  }
}

.iso__custom__product__select__dropdown {
  & > h6 {
    display: block;
    @include small {
      display: none;
    }
  }

  & > .container {
    padding-top: 0 !important;
  }
}

.bestprice-price-details {
  &-code__input {
    display: flex;

    & > button {
      min-width: initial;
    }

    &__inner {
      position: relative;
      margin-right: 10px;

      & > input {
        padding-right: 2rem;
      }

      & > svg {
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        right: 7px;
        top: 20px;
        transform: translateY(-50%);
        z-index: 2;
      }
    }
  }

  &__promotion {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      color: $primary;
      margin-top: 10px !important;

      & > span {
        word-break: break-all;
      }
    }

    & span {
      color: $primary;
    }
  }

  &__promotion__price {
    white-space: nowrap;
    padding-left: 0.5rem;
  }

  &__breakWord {
    word-break: break-all;
  }
}

.form-check {
  display: flex;
}

.iso__package__search_validation_error {
  font-size: 14px;
  color: $red;

  & div {
    word-break: break-word;
  }
}

.package__search__validation-error {
  &-tooltip-popper {
    opacity: 1 !important;
    z-index: 3;

    & .arrow {
      width: 1rem !important;
      height: 0.75rem !important;

      &::before {
        border-width: 0 0.6rem 0.6rem !important;
        bottom: 5px !important;
        border-bottom-color: #eee !important;
      }
    }
  }

  &-tooltip-inner {
    background-color: $white;
    border: 1px solid #eee;
    border-radius: 4px;
    max-width: 290px;
    width: 290px;
    box-shadow: 0px 24px 72px rgba(0, 0, 0, 0.1019607843);
  }

  &-tooltip-inner-container {
    position: relative;
    padding: 10px;
    text-align: left;
    padding-right: 35px;
  }

  &-tooltip-inner-close {
    color: $red;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px;
  }
}

.paxRoomSelection__bottom {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    z-index: 2;
    background: $white;
    margin-bottom: 0 !important;
    border-top: 1px solid #e4ecef !important;
    padding: 0 1rem 0.5rem 1rem !important;
  }

  & > .iso__package__search_validation_error {
    margin-bottom: 10px;
  }
}

.paxRoomSelection__spacing__when__mobile {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    height: 300px;
  }
}

.pax-room-selection__modal .modal-content {
  overflow: auto;
  border: none;
  border-radius: 0;
}

.iso__dateRangeInput__customContainer__calendar {
  height: 370px;
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    height: 470px;
  }
}

.iso__dateRangeInput__customContainer__validation__errors {
  padding: 0rem 1rem 1rem 1rem;
}

.text-lg-end {
  @include large {
    text-align: right;
  }
}

.hide_search_bar_on_new_tab .iso__service-search,
.hide_search_bar_on_new_tab .back-button {
  opacity: 0;
}

.iso__service-search__column-headline {
  position: relative;
  padding-left: 25px;

  & > svg {
    position: absolute;
    left: 0px;
  }
}

.internal-flight-modal {
  & .modal-content {
    border: 0px solid;
    border-radius: 8px;
  }

  .fw-bold {
    font-weight: bold;
  }

  .fs-5 {
    font-size: 1.25rem;
  }

  .modal-close-box > svg {
    font-size: 1.25rem;
  }

  .modal-close-box {
    cursor: pointer;
    padding: 0.5rem;
    padding-right: 0;
  }
}
