.page__center {
  .Poster {
    &__image {
      position: relative;
      display: flex;
      z-index: -1;
      height: 200px;
      width: calc(100vw - 10px);
      margin-left: calc(50% - 50vw);

      > img {
        position: absolute;
        width: 100%;
        height: 75vh;
        object-fit: cover;
        left: 0;
        z-index: -1;
        transform: translate(0, calc(-50% + 100px));
      }
    }
  }
}
