@import './variables';

.flight-page {
  .iso__nav__tabs-container {
    .search-collapsible {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start !important;

      .link-button {
        @extend .btn;
        @extend .btn-block;
        @extend .btn-primary;

        margin-top: 20px;
      }
    }

    .alert.alert-warning {
      margin: 2rem 0 0;
    }
  }
}

.custom-control {
  // idk why it (z-index 1) shows above header menu (100)
  z-index: unset;
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .iso__service-search {
    font-size: 95%;
  }
  .iso__service-search .iso__date-range-input {
    padding: 0.375rem 0.45rem;
  }
}

.iso__flightSearchResultDetails {
  &__stop::before {
    background: $primary;
  }
}

.btn-secondary {
  background-color: $primary;
  border-color: $primary;
}

.btn-secondary:hover {
  background-color: darken($primary, 5%);
  border-color: darken($primary, 5%);
}

.btn-outline-secondary {
  border-color: $primary;
  color: $primary;
}

.btn-outline-secondary:hover {
  border-color: $primary;
  background-color: $primary;
}

.flight-info-selected-container {
  border-color: $primary;
}

.flight-info-notch-top {
  border-top: 0 solid $primary;
  border-left: 3px solid $primary;
  border-right: 3px solid $primary;
  border-bottom: 3px solid $primary;
}

.flight-info-notch-bottom {
  border-top: 3px solid $primary;
  border-left: 3px solid $primary;
  border-right: 3px solid $primary;
  border-bottom: 0 solid $primary;
}
