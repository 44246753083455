@charset "utf-8";

// All of bootstrap, but our own vars file
@import '~bootstrap/scss/functions';

// only bootstrap overrides/extends here
// put custom or non-bootstrap styles into Base.scss

html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
  margin: 0;
}
