body {
  /*
  Default colors that could be overwritten in cms
  Provide your colors in hsl format!
  */
  --primaryColor: hsl(0, 99%, 42%);
  --primaryColor-h: 0;
  --primaryColor-s: 99%;
  --primaryColor-l: 42%;
  --primaryColor-a: 1;

  --secondaryColor: hsl(179, 38%, 54%);
  --secondaryColor-h: 179;
  --secondaryColor-s: 38%;
  --secondaryColor-l: 54%;
  --secondaryColor-a: 1;

  --accentColor: hsl(40, 90%, 69%);
  --accentColor-h: 40;
  --accentColor-s: 90%;
  --accentColor-l: 69%;
  --accentColor-a: 1;

  --priceColor: hsl(0, 99%, 42%);
  --priceColor-h: 0;
  --priceColor-s: 99%;
  --priceColor-l: 42%;
  --priceColor-a: 1;

  --footerColor: hsl(40, 90%, 69%);
  --footerColor-h: 40;
  --footerColor-s: 90%;
  --footerColor-l: 69%;
  --footerColor-a: 1;

  --primaryColorContrast: hsl(0, 0%, 0%);
  --primaryColorContrast-h: 0;
  --primaryColorContrast-s: 0%;
  --primaryColorContrast-l: 0%;
  --primaryColorContrast-a: 1;

  --secondaryColorContrast: hsl(0, 0%, 0%);
  --secondaryColorContrast-h: 0;
  --secondaryColorContrast-s: 0%;
  --secondaryColorContrast-l: 0%;
  --secondaryColorContrast-a: 1;

  --accentColorContrast: hsl(0, 0%, 0%);
  --accentColorContrast-h: 0;
  --accentColorContrast-s: 0%;
  --accentColorContrast-l: 0%;
  --accentColorContrast-a: 1;

  --priceColorContrast: hsl(0, 0%, 0%);
  --priceColorContrast-h: 0;
  --priceColorContrast-s: 0%;
  --priceColorContrast-l: 0%;
  --priceColorContrast-a: 1;

  --footerColorContrast: hsl(0, 0%, 0%);
  --footerColorContrast-h: 0;
  --footerColorContrast-s: 0%;
  --footerColorContrast-l: 0%;
  --footerColorContrast-a: 1;

  @font-face {
    font-family: 'mainFont';
    src: url('./Fonts/Poppins-Light.ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'mainFont';
    src: url('./Fonts/Poppins-Regular.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'mainFont';
    src: url('./Fonts/Poppins-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'mainFont';
    src: url('./Fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
  }

  // not configurable variables
  --light: #e9ecef;
}
