@import '../utils/mediaQueries';

.page__center {
  .offer-fixed-column-layout-row {
    display: none;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 1rem;
    }
    @include medium {
      display: flex;
    }
    .offerFixedColumnLayoutColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 40rem;
    }
  }
  .bigOffer {
    width: 100%;
    height: 100%;
    .offerContainer,
    .offerContainer img {
      max-height: 100%;
      min-height: 100%;
      border-radius: 1rem;
    }
    .offerBottomLeft {
      font-size: 1.5rem !important;
    }

    .offerTopRight {
      font-size: 1.5rem !important;
    }

    .offerBottomRight {
      font-size: 1.5rem !important;
    }

    .offerContainer {
      .offer__hotelLogo-container {
        width: auto;
        height: 30%;
        bottom: 0;
        left: 0;
        position: absolute;
      }

      .offer__hotelLogo {
        min-height: unset !important;
        width: auto;
        height: 100%;
        border-bottom-left-radius: 0.9rem;
        opacity: 0.8;
      }
    }
  }

  .smallOffer {
    .offerContainer {
      .offer__hotelLogo-container {
        height: 30%;
        width: auto;
        bottom: 0;
        left: 0;
        position: absolute;
      }

      .offer__hotelLogo {
        min-height: unset !important;
        height: 100%;
        width: auto;
        border-bottom-left-radius: 0.9rem;
        opacity: 0.8;
      }
    }
  }

  .offerCol-0 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .offerCol-1 {
    padding-right: 0.5rem;
  }

  .topOffer {
    padding-bottom: 0.25rem;
    min-height: 12rem;
    img {
      min-height: 11.75rem;
      max-height: 11.75rem;
    }
  }

  .bottomOffer {
    padding-top: 0.25rem;
    min-height: 12rem;
    img {
      min-height: 11.75rem;
      max-height: 11.75rem;
    }
  }
}

.page__top {
  .offer-fixed-column-layout-row {
    min-height: 40rem;
    max-height: 40rem;
    display: none;
    @include medium {
      display: flex;
    }
    img {
      -o-object-fit: cover;
      object-fit: cover;
    }

    .offerCol-0 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .offerCol-1 {
      padding-right: 0.5rem;
    }

    .topOffer {
      padding-bottom: 0.25rem;
      min-height: 20rem;
      max-height: 20rem;
      img {
        min-height: 19.75rem;
        max-height: 19.75rem;
      }
    }

    .bottomOffer {
      padding-top: 0.25rem;
      min-height: 20rem;
      max-height: 20rem;
      img {
        min-height: 19.75rem;
        max-height: 19.75rem;
      }
    }

    .offerFixedColumnLayoutColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 40rem;
    }

    .smallOffer {
      .offerContainer {
        img {
          border-radius: 1rem;
        }
        .offerBottomLeft {
          font-size: 1.25rem;
        }

        .offerTopRight {
          font-size: 1.25rem;
        }

        .offerBottomRight {
          font-size: 1.25rem;
        }

        .offer__hotelLogo-container {
          width: auto;
          height: 30%;
          bottom: 0;
          left: 0;
          position: absolute;
        }

        .offer__hotelLogo {
          min-height: unset !important;
          width: auto;
          height: 100%;
          border-bottom-left-radius: 0.9rem;
          opacity: 0.8;
        }
      }
    }

    .bigOffer {
      .offerContainer,
      .offerContainer img {
        min-height: 40rem;
        max-height: 40rem;
        border-radius: 1rem;
      }

      .offerBottomLeft {
        font-size: 1.25rem;
        @include medium {
          font-size: 3.5rem;
        }
      }

      .offerTopRight {
        font-size: 1.25rem;
        @include medium {
          font-size: 2.5rem;
        }
      }

      .offerBottomRight {
        font-size: 1.25rem;
        @include medium {
          font-size: 2rem;
        }
      }

      .offerContainer {
        .offer__hotelLogo-container {
          width: auto;
          height: 30%;
          bottom: 0;
          left: 0;
          position: absolute;
        }

        .offer__hotelLogo {
          min-height: unset !important;
          width: auto;
          height: 100%;
          border-bottom-left-radius: 0.9rem;
          opacity: 0.8;
        }
      }
    }
  }
}

.offer-carousel-layout-row {
  display: initial;
  img {
    border-radius: 1rem;
    -o-object-fit: cover;
    object-fit: cover;
    max-height: 100%;
    min-height: 100%;
  }
  @include medium {
    display: none;
  }
}

.resize-offer-title {
  .bigOffer {
    .offer-bottom {
      .offerBottomLeft {
        font-size: 1rem !important ;
        @include medium {
          font-size: 1.15rem !important ;
        }
        @include large {
          font-size: 1.5rem !important ;
        }
      }
    }
  }
}

.offer-fixed-column-layout-column-8x4-2 {
  .bigOffer {
    .offerContainer {
      min-height: 24rem !important;
      max-height: 24rem !important;
    }
    .new__offer__container {
      & img {
        height: 500px;
      }
    }
  }
  .offerFixedColumnLayoutColumn {
    max-height: none !important;
  }
}
