.iso__teaser {
  box-shadow: 0px 0px 12px rgba(185, 188, 200, 0.3);
  border: 0px solid rgba(46, 45, 44, 0.125);
  border-radius: 0px;
  cursor: pointer;
  & .card-title {
    font-weight: 700;
    color: $primary;
  }
  .card-img-top {
    border-radius: 0px;
    object-fit: cover;
    height: 240px;
    @include medium {
      height: 270px;
    }
  }
}
.teaser-link {
  text-decoration: none !important;
  color: initial;
}
