.iso__custom__product__select__input {
  border: 1px solid $secondary !important;
  border-radius: 4px;
}
.iso__custom__product__select__backspace__wrapper {
  display: none !important;
}

.productSelect-country-folder {
  color: $primary;
  font-size: 0.75rem !important;
  padding: 4px 15px;
  align-self: center;
}
