@import './Styles';
.iso-nav {
  .burger {
    &__icon span:after {
      background-color: $black;
    }
    &__icon span:before {
      background-color: $black;
    }
    &__icon span {
      background-color: $black;
    }
  }

  svg {
    stroke: $black;
  }
  font-weight: 400;
  padding-top: 16px;

  .navbar {
    cursor: pointer;
    padding: 0;
  }

  .nav-item {
    padding-bottom: 0;
    text-transform: uppercase;
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .navbar-toggler {
    border: none;
    padding: 0;
  }

  .navbar-toggler-icon {
    color: var(--primary);
  }

  .nav-item {
    margin-right: 25px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--primary);
    text-decoration: none;
    font-weight: 400;
  }

  .navbar-light .navbar-nav .nav-link:not(.dropdown-toggle) {
    font-size: 1rem;
    transition: border 500ms ease-out, color 500ms ease-out;
    white-space: nowrap;
    padding-right: 0.33rem;
    padding-left: 0.33rem;
  }

  .navbar-light .navbar-nav .nav-link:hover:not(.dropdown-toggle),
  .navbar-light .navbar-nav .nav-link.active:not(.dropdown-toggle) {
    text-decoration: none;
    padding-right: 0.33rem;
    padding-left: 0.33rem;
    color: #3d6e73;
  }

  a + a {
    margin-left: 1.5rem;
  }
}

.headerSelectionLayout__label {
  text-transform: uppercase;
  margin-top: 2px;
}

.headerSelectionLayout {
  .iso__dropdown {
    height: 24px;
    border: none;
    border-radius: 0;
    //display: inline;
  }
  .iso__dropdown__toggle:not(:disabled):not(.disabled):focus,
  .iso__dropdown__toggle:not(:disabled):not(.disabled):active {
    background-color: $white;
    color: $black;
  }

  .iso__dropdown__toggle:hover {
    background-color: white;
    color: $primary;
  }

  .dropdown-menu {
    min-width: 3.5rem;
    border: none;
    border-radius: 0;
    font-weight: 600;
    background-color: $white;
    padding: 0;
    left: -10px !important;
  }

  .dropdown-item {
    font-weight: 700;
  }

  .dropdown-item:hover,
  .dropdown-item:active {
    background-color: $white;
    color: $primary;
    font-weight: 600;
  }

  .btn {
    padding: 0;
    border-radius: 0;
    border: none;
    color: $black;
    font-weight: 600;
    min-width: 35px;
  }

  cursor: pointer;
  position: relative;
}

.logoContainer {
  display: contents;
}

.dropdown-item {
  cursor: pointer;
  padding: 0;
}

.toggler:focus {
  outline: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0; // override default ul
  color: #444343;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: white;
  background-clip: padding-box;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }

  .dropdown-toggle {
    &::before {
      vertical-align: 0;
    }
  }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu {
  &[x-placement^='top'],
  &[x-placement^='right'],
  &[x-placement^='bottom'],
  &[x-placement^='left'] {
    right: auto;
    bottom: auto;
  }
}

// Dividers (basically an `<hr>`) within the dropdown

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: 0;
  clear: both;
  font-weight: 200;
  color: $black;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703

  &.active,
  &:active {
    color: $black;
    background-color: darken($white, 10%);
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    color: black;
    pointer-events: none;
    background-color: transparent;
  }
}

.dropdown-menu.show {
  padding: 0.5rem 1rem;
  display: block;
  width: max-content;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  margin-bottom: 0; // for use with heading elements
  color: yellow;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  color: green;
}

.prevent-currency-language-change {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  & > div {
    position: relative;
    & > div {
      opacity: 0;
      z-index: 1001;
      display: none;
      transition: 100ms;
      position: absolute;
      top: 35px;
      right: -1rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 0.75rem 1rem;
      border-radius: 6px;
      width: 300px;
      & > span {
        color: var(--dark);
      }
      & > p {
        color: $red;
        margin-bottom: 0rem;
        font-weight: 800;
      }
      background: $white;
    }
    & > div:after {
      bottom: 100%;
      left: 91%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $white;
      border-width: 11px;
      margin-left: -11px;
    }
  }
  &--fade > div > div {
    opacity: 1;
    display: block;
    z-index: 1001;
  }
}

.wrap-currency-change {
  position: relative;
}

.iso__header {
  width: 100%;
  &__wrapper {
    position: relative;
    background-color: $white;
    width: 100%;
    z-index: 1000;
    box-shadow: $defaultShadow;
  }
  &--desktop {
    padding: 0.5rem 1rem;
  }
  .login-button {
    color: $primary;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    & > svg {
      margin-right: 0.35rem;
      font-size: 1.1rem;
    }
  }

  &__collapse__box {
    padding: 0rem 1rem 0rem 1rem;
    overflow: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-1500px);
    transition: transform 300ms;
    background-color: $white;
    & svg {
      font-size: 1.2rem;
    }
    & .login-button-a {
      font-size: 1.2rem;
    }
    & .top-header--mobile {
      padding-top: 0.5rem;
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 1055;
      margin-bottom: 1rem;
    }
  }
  .menu--is-open {
    transform: translateX(0);
  }
  .z-100 {
    z-index: 100;
  }
  .z-102 {
    z-index: 102;
  }
  .navbar-link-container {
    &--desktop {
      & span {
        color: $black;
        font-weight: 500;
      }
      & a {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding: 0.25rem;
      }
    }
    &--mobile {
      text-transform: uppercase;
      color: $black;
      margin-left: 1.5rem;
      font-weight: 500;
      &:hover {
        font-weight: 700;
      }
    }
  }
  & .image {
    height: 55px;
    width: auto;
  }
  & .login-button-a {
    margin-right: 2rem;
  }
  & .language-dropdown {
    margin-right: 2rem;
  }
  & .header-top {
    position: static;
    right: 0;
    font-size: 85%;

    button,
    button:hover {
      color: initial !important;
      background-color: $white !important;
    }
  }
}
.top-header--mobile {
  width: 100%;
  & .image {
    height: 70px;
    width: auto;
  }
  & .login-button-a {
    margin-right: 0;
  }
}
.top-header--fixed {
  position: fixed;
  left: 1rem;
  top: 0.5rem;
  right: 1rem;
  width: initial;
}
.iso__header--mobile {
  & .menu-icon {
    cursor: pointer;
    font-size: 1.2rem;
  }
}

.navlink-container {
  // height: 100%;
  & div {
    margin-bottom: 0.75rem;
    font-weight: 700;
  }
}

.menu-sticky--down {
  position: sticky;
  bottom: 0;
  background-color: $white;
  z-index: 1055;
}
.iso__alert__container {
  width: 100%;
}

.header-down {
  display: flex;
  justify-content: start;
  align-items: end;
  .image-container {
    margin-right: 1.5rem;

    img {
      height: 90px;
      width: auto;
    }
  }
}

.login-button-dropdown-container {
  .iso__dropdown {
    background-color: unset;
    border-radius: unset;
    .iso__dropdown__toggle {
      border: unset;
      padding: 0;
      .svg-inline--fa {
        color: $primary;
      }
      .svg-inline--fa {
        margin-right: 0.5rem;
      }
    }
    .iso__dropdown__toggle:hover {
      background-color: unset;
    }
    .btn:focus {
      box-shadow: unset;
    }
    .btn {
      padding-top: 0;
    }
  }
}

.login-modal {
  .modal-header {
    border-bottom: unset;
    .close {
      color: $primary;
      font-size: 20px;
    }
  }
  .form-control {
    border-color: $secondary;
  }
  .btn {
    box-shadow: $defaultShadow;
    font-weight: normal;
  }
  .btn-block {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  }
  .btn-link {
    color: $red;
  }
  .btn-link:hover {
    color: $red;
  }
}

.login-modal-container {
  background-color: #e1f2f15f;
}

.iso__modal-dialog {
  font-family: $font-family-base;
  .btn {
    font-weight: normal !important;
    text-transform: unset !important;
  }
  .modal-header {
    border-bottom: unset;
    .close {
      color: $primary;
      font-size: 20px;
    }
  }
  .modal-footer {
    border-top: unset;
  }
  .modal-content {
    .form-control {
      border-color: $secondary;
      border-radius: $input-border-radius;
    }
  }
  .modal-title {
    color: $dark-violet;
  }
}
