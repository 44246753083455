.best-price-package-details__modal {
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;

  &__close {
    margin-right: 24px;
  }

  .modal-content {
    height: fit-content;
    min-height: 100%;
  }

  &__name-row {
    min-height: fit-content;
    height: fit-content;
  }

  .iso__image-gallery__container {
    height: 75%;
    max-height: 75%;
    width: calc(100vw - 2rem);
    max-width: calc(100vw - 2rem); // - 2 x modal body padding

    .image-gallery-image {
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        max-height: calc(75vh - 80px) !important;
      }
    }

    .image-gallery {
      height: 100%;

      &-content {
        height: 100%;
        display: grid;
        .image-gallery-slide .image-gallery-image {
          max-height: calc(100vh - 240px) !important;
        }
        .image-gallery-slide-wrapper,
        .image-gallery-thumbnails-wrapper {
          padding-bottom: 0 !important;
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); // - 2 x modal body padding
        }
      }

      &-thumbnails-wrapper {
        margin-top: auto;
        padding-bottom: 3rem;
      }

      &-slide {
        width: calc(100vw - 2rem);
        max-width: calc(100vw - 2rem); // - 2 x modal body padding

        &-image {
          width: 100%;
          max-width: 100%; // - 2 x modal body padding
        }
      }
    }
  }
}
