.geounit-checkbox {
  &__angle {
    font-size: unset;
    margin-right: 5px;
  }

  &__container {
    @include medium {
      min-width: 400px !important;
    }
    max-width: unset;

    &__inner {
      & > div {
        @include medium {
          max-width: 50%;
        }
        max-width: 100%;
        line-break: anywhere;
      }
    }
  }

  &__popover {
    z-index: 3;

    button.btn.btn-secondary {
      width: 75%;
      color: hsla(0, 0%, calc(-100 * (var(--primaryColor-l) - 58.59375%)), 1);
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
    }
  }
}
