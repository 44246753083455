@import '../utils/mediaQueries';

.bestprice-price-details__modal__checkout {
  padding-top: 3rem;
}

.checkout__container {
  .traveller--entry {
    padding: 20px;
    border-radius: 16px;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .element--renderer {
      margin-bottom: 0 !important;
    }
  }

  .optional-service {
    &__headline {
      font-size: 20px;
      font-weight: 500;
    }

    &__badge {
      position: absolute;
      right: 0;
      top: 0;
      background-color: $accentColor;
      color: #fff;
      border-radius: 0 16px 0 16px;
      padding: 5px 20px 5px 20px;
    }

    .card {
      box-shadow: $defaultShadow;
      border: none;
      border-radius: 16px;
      background-color: $white;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card-img-top {
      max-height: 240px;
      object-fit: cover;
      border-radius: 0 17px 0 0;
    }

    .iso__price__suffix {
      color: $black;
    }

    .iso__price__prefix {
      font-weight: 700;
      font-size: 12px;
    }

    .luggageCard_header {
      margin-top: 10px;
    }

    .luggageCard_subHeader {
      font-size: 1rem;
    }
  }

  .btn.btn-primary,
  .btn.btn-outline-primary,
  .btn.btn-secondary {
    min-width: 120px !important;
  }

  &__wrapper {
    display: flex;
    height: 100%;
  }

  &__price {
    margin-top: 45px;
    height: auto;
    display: none;
    flex-basis: 25%;
    flex-direction: column;

    @include medium {
      display: flex;
    }

    & .bestprice-price-details {
      border-radius: 16px;
      @include medium {
        box-shadow: $defaultShadow;
      }
    }

    & .bestprice-price-details__image {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  .bestprice-price-details {
    padding: 0;

    &__total {
      padding: 14px;
      margin-top: 0;
      display: flex;
      justify-content: end;
      align-items: center;

      & > div {
        margin-right: 5px;
      }

      button {
        color: $primary;
        margin-right: auto;
      }

      &__price {
        font-size: 1.25rem !important;
      }

      &__voucher {
        font-size: 1rem;
      }
    }

    &__main__container {
      padding: 0 16px 16px;
    }

    &__image {
      width: 100%;
      max-height: 170px;
      object-fit: cover;
      border-radius: 1rem;
    }

    &__headline {
      &__container {
        position: relative;
        width: 100%;

        .bestprice-price-details {
          &__hotelName {
            position: absolute;
            top: 80px;
            left: 20px;
            color: $white;
            font-size: 20px;
          }

          &__icons {
            position: absolute;
            top: 20px;
            left: 30px;
            color: white;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-self: start;

    @include medium {
      margin-right: 20px;
    }

    @include medium {
      flex-basis: 75%;
    }

    &__title {
      font-size: 24px;
      color: $black;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    &__optionalExtras {
      max-width: 100vw;
    }
  }

  .formContainer {
    background-color: $white;
    border-radius: 16px;
    padding: 20px;

    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .generalTermsAndConditions {
    &_mandatoryFieldError {
      color: $danger;
    }

    &_customInput,
    &_item {
      display: inline;
    }
  }

  .preferencesSection {
    &_mandatoryFieldError {
      color: $danger;
    }

    &_customInput,
    &_item {
      display: inline;
    }
  }

  .element-renderer {
    justify-content: flex-start;

    .form__error {
      position: relative;
      top: 0;
    }
  }

  .roomContainer {
    background-color: $gray-200;
    border-radius: 1rem;
    box-shadow: $defaultShadow;

    > div {
      margin: 0 0.25rem;

      &:not(.roomInfo) {
        background-color: #fff;
      }

      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }

    .roomInfo {
      margin: 0 0.5rem;
    }

    .roomInfo {
      padding: 1rem 0;
    }
  }
}

.form__datepicker.input--error {
  svg {
    position: initial !important;
  }
}

.form__datepicker {
  .validation-error {
    color: var(--red);
    font-size: 0.8rem;
    position: absolute;
    top: 46px;
    right: 0;
  }
}

.iso__date-picker__modal__label {
  visibility: hidden;
}

.react-datepicker {
  button.react-datepicker__navigation {
    height: 30px;
    margin: 0 15px;
  }

  &__navigation-icon::before {
    border-color: $black !important;
    width: 12px;
    height: 12px;
  }

  &__current-month {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 2rem;

    @include medium {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  &__header__dropdown--select {
    margin-bottom: 2rem;

    @include medium {
      font-size: 1rem;
    }
  }

  &__month-dropdown-container--select {
    width: 60%;
    margin: 0 0.5rem;

    @include medium {
      width: 50%;
    }
  }

  &__year-dropdown-container--select {
    width: 30%;
    margin: 0 0.5rem;
  }

  &__month-select,
  &__year-select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 1.2rem;

    @include medium {
      font-size: 1rem;
    }
  }
}
