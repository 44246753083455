@import './variables';

.iso__footer {
  font-weight: 300;
  color: $footerColorContrast;
  background-color: $footerColor;
  box-shadow: $defaultShadow;
  border-radius: 6px;
  overflow: hidden;

  &__wrapper {
    background-color: $white;
    padding: 0.75rem;
    & .footer-col {
      margin-bottom: 1rem;
      padding: 2rem;

      a div {
        margin-bottom: 0.5rem;
        color: $footerColorContrast;
      }
    }
    & .footer-header {
      margin-bottom: 1rem;
    }
    & .footer-description {
      margin: 2rem 0;
    }
    & .footer-image-container {
      background-color: $white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      & .footerlogo {
        width: 100%;
        max-width: 210px;
        height: auto;
      }
    }
    & .footer-corporateInformation-wrapper {
      background-color: $secondary;
      color: $secondaryColorContrast;
    }
    & .footer-corporateInformation {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      p {
        margin: 0;
      }
    }
    & .footer-copyright {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
  }
}
