@media only print {
  .hide-in-print {
    display: none;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
  }

  .print-table {
    border: none;
  }
  .print-table thead,
  .print-table tbody,
  .print-table tfoot,
  .print-table tr,
  .print-table td {
    border: none;
    padding: 0;
    margin: 0;
  }

  .print-footer-space {
    display: table-header-group;
    height: 35px;
  }

  .best-price-package-details {
    margin-top: -40px;

    &__location {
      &__container {
        flex: 0 0 100% !important;
        max-width: 100% !important;

        @include medium {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
      }
    }
    &__logo {
      height: 20px;
    }
    .picture-gallery {
      margin-bottom: 35px;

      &__large {
        padding-top: 45%;
        flex: 0 1 calc(71% - 0.18rem);
      }

      &__small {
        display: flex;
      }
    }

    &__print-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      justify-content: center;

      .image {
        height: auto;
        width: 70px;
      }

      &__email {
        font-size: 14px;
      }
    }

    &__print-info {
      display: flex;
      margin-bottom: 25px;
      justify-content: space-between;

      > div {
        flex: 1 1 auto;
      }

      .bestprice-price-details__content__container {
        margin-top: 10px;
      }
    }

    &__print-section {
      page-break-after: always;
    }
  }

  .bestprice-price-details {
    &__total {
      &__price {
        font-size: 2.5rem;
        color: $primary;
      }
    }
  }

  .iso__header {
    .image {
      height: 80px;
      width: auto;
      margin-bottom: -20px;
    }
  }
}

@page {
  margin: 20mm 20mm 4mm;
}

@media only screen {
  .hide-in-screen {
    display: none;
  }
  .print-table {
    display: block;
    width: 100%;
    border-collapse: collapse;
    border: none;
  }

  .print-table thead,
  .print-table tbody,
  .print-table tfoot,
  .print-table tr,
  .print-table td {
    display: block;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
