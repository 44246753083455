.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondary;
  border-color: $secondary;
}

.custom-control-input {
  &:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 0.25);
  }

  &:checked ~ .custom-control-label::before {
    color: $secondary;
    border-color: $secondary;
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: lighten($secondary, 25%);
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: lighten($secondary, 35%);
    border-color: lighten($secondary, 35%);
  }

  &:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba($black, 0.075), 0 0 0 0.2rem rgba($secondary, 0.25);
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: lighten($secondary, 25%);
  }
}

.custom-radio {
  .custom-control-label {
    &::before {
      border: $black solid 1px;
      width: 1.125rem;
      height: 1.125rem;
    }

    &::after {
      left: -1.813rem;
      top: -0.063rem;
      width: 1.688rem;
      height: 1.688rem;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: #fff;
      border: $black solid 1px;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='hsl(0, 99%, 42%)'/%3e%3c/svg%3e");
    }
  }
}
