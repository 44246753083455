// Override SCSS vars (aka global theme)
// --------------------------------------------

$mainFont: 'mainFont';

$headings-font-family: $mainFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-sans-serif: $mainFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: $mainFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: $mainFont, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

$primary: var(--primaryColor);
$secondary: var(--secondaryColor);
$green: #e1f2f1;
$red: #eb4d4d;
$blue: #283583;
$turquoise: #5cb5b4;
$yellow: #f7c667;
$lightyellow: #f1e5ac;
$success: $green;
$dark-violet: #332852;
$lightred: #f1aaaa;
$lightturquoise: #eef7f7;
$darkGreen: #008020;

$accentColor: var(--accentColor);
$priceColor: var(--priceColor);
$footerColor: var(--footerColor);

/* those properties will be either black or white depending on lightness of main color ($primary, $secondary etc.)
 Should be used as font color along with background in the corresponding color */

$primaryColorContrast: var(--primaryColorContrast);
$secondaryColorContrast: var(--secondaryColorContrast);
$accentColorContrast: var(--accentColorContrast);
$priceColorContrast: var(--priceColorContrast);
$footerColorContrast: var(--footerColorContrast);

$gray-100: #f2f2f2;
$gray-200: #ededed;
$gray-300: #e4ecef;
$gray-400: #d7d7d7;
$gray-500: #9d9d9c;
$gray-600: #898989;
$gray-700: #5f5f5f;
$gray-800: #28282f;
$gray-900: #28282f;
$gray: #cccccc;
$lightgray: var(--light);
$black: #2e2d2c;
$white: #fff;

$font-weight-normal: 300;
$headings-font-weight: 300;
$body-color: $black;
$input-color: $black;
$input-placeholder-color: $gray-500;

$grid-gutter-width: 1rem;

$spacers: () !default;
$spacer: 1rem;

$spacers: map-merge(
  (
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 5
    ),
    8: (
      $spacer * 10
    )
  ),
  $spacers
);

$btn-border-radius: 4px;
$input-border-radius: 4px;
$link-color: $black;
$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 700px !default;
$modal-sm: 300px !default;

$defaultShadow: 0px 24px 72px #0000001a;
$heavyTopShadow: 0 -4px 16px rgba(0, 0, 0, 0.2);
$confirmationShadow: 0px 3px 6px #00000029;
