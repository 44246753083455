.iso__serviceList__mobile-section {
  margin-bottom: 42px;
  @include medium {
    margin-bottom: 0;
  }
  & > div > button {
    background: $white;
    border: 3px solid $primary;
    color: $primary !important;

    &:hover,
    &:focus,
    &:active {
      background: darken($white, 2.5%);
    }

    svg,
    .filters-count {
      display: none;
    }
  }
}

.modal {
  .modal-dialog.filter__mobile {
    margin: 0;
    height: 100%;

    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;

      .modal-header {
        border-bottom: none;

        .modal-title svg {
          color: $red;
          font-size: 2rem;
        }
      }

      .modal-body {
        background: $white;
        padding-bottom: 75px;

        .filter__mobile__apply-button {
          width: 100%;
          background: $white;
          height: 75px;
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 2;
          border-top: 1px solid $gray-300;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 100%;
            margin: 2rem;
            background: $primary;
            color: $white;
          }
        }

        .iso__baseLayoutFilter__formGroup {
          padding: 4px 0 !important;

          .custom-checkbox {
            .custom-control-label::before {
              border-color: $secondary;
              border-radius: 1px;
            }
            .custom-control-input:checked ~ .custom-control-label::after {
              background-size: 16px;
            }
          }
        }
      }
    }
  }
}
