@import '../utils/mediaQueries';

.communicationModal {
  &_container {
    padding: 1.5rem;

    border-radius: 16px;

    width: min(400px, 90vw);

    bottom: 130px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1001;

    background-color: white;
    box-shadow: $defaultShadow;

    &_inner {
      height: 90%;
    }

    @include small {
      right: 90px;
      transform: none;
      bottom: 70px;
    }
  }

  &_closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.1rem;
    cursor: pointer;

    svg {
      transform: rotate(45deg);
    }
  }

  &_header {
    padding-bottom: 0.5rem;
  }

  &_input {
    height: 20vh !important;
  }

  &_send-button {
    border-radius: 4px;
  }

  &_cancel-button {
  }
}
