$var-border-radius: 1px;

@import '~klaro/src/scss/klaro.scss';

body {
  --green1: var(--primaryColor);
  --green2: var(--primaryColor);
  --blue1: var(--primaryColor);
  --dark2: var(--primaryColor);
}

.klaro .cookie-modal .cm-btn,
.klaro .cookie-notice .cm-btn {
  padding: 6px 12px 6px 12px;
}

.cn-buttons {
  display: flex;
  width: 100%;
  gap: 40px;
}

@media (max-width: 480px) {
  .cn-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

.contentNotice {
  background: none;
  border: none;
  color: var(--primaryColor);
  font: inherit;
  padding: 0;

  > p {
    text-decoration: underline !important;
    color: var(--primaryColor) !important;
    font-weight: 400 !important;
  }
}

.klaro .cookie-modal-notice,
.klaro .cookie-modal .cm-modal.cm-klaro {
  max-width: 700px;
  border-radius: 8px;
  padding: 1.5rem;
}

.klaro {
  .cookie-modal,
  .context-notice,
  .cookie-notice {
    .cm-btn {
      flex: 1;
      border: 1px solid;
      border-radius: 4px;
      background-color: #fff;
      color: var(--primaryColor);
      margin-right: 0;
      &.cm-btn-info,
      &.cm-btn-success {
        background-color: var(--primaryColor);
        color: #fff;
      }
    }
  }
}

.cn-body {
  display: flex;
  flex-direction: column;

  .cn-header {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.klaro .cookie-modal .cm-modal .cm-header h1 span {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 480px) {
  .klaro .cookie-modal .cm-modal .cm-footer .cm-footer-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

p {
  order: 2;
}

.container div {
  order: 1;
}

@media only print {
  .klaro {
    display: none !important;
  }
}
