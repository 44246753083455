@import '../utils/mediaQueries';

.newsletter-wrapper {
  display: flex;
  justify-content: center;
}

.newsletter {
  padding-left: 8px;
  padding-right: 8px;

  @include medium {
    max-width: 800px;
  }

  form {
    .form-buttons {
      margin-top: 0 !important;
      height: 50%;
      > div {
        padding-bottom: 1rem;
        justify-content: flex-start !important;
      }

      .btn {
        padding-left: 20px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-12px);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent white;
        }
      }
    }

    input {
      border: 1px solid $secondary;
    }
  }

  &__form-container {
    @include medium {
      margin-bottom: 8px !important;
    }
  }

  &__termsAndConditions {
    display: flex;
    align-items: start;
    @include medium {
      width: 800px;
    }
  }

  label {
    margin-bottom: 0;
  }

  .termsAndConditions {
    & > div {
      display: flex;
      align-items: flex-start;
    }

    & label {
      order: 2;
      margin-left: 8px;
      margin-top: -6px;
    }

    & input {
      order: 1;
      position: relative;
      margin-left: 0;
      margin-top: 0;
    }
  }

  .element-renderer {
    justify-content: flex-start;
  }

  .form__error {
    position: relative;
    top: 0;
  }
}
