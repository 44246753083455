@import './cssVars';
@import './Vars';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'functions-override';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~@ibe/components/dist/Styles/stylesNoBootstrap';
@import './Header';
@import './Styles';
@import './Flight';
@import './confirmation';
@import './PackageListItem';
@import 'Components/Search';
@import 'Components/Offer';
@import 'Components/OfferFixedColumnLayout';
@import 'Components/bestprice';
@import 'Components/bestpricePrint';
@import 'Components/Checkout';
@import 'Components/CommunicationModal';
@import 'Components/Newsletter';
@import 'Components/PasswordChange';
@import 'Components/CustomIndexedDestinationSelect';
@import 'Components/Poster';
@import 'Components/Destinations';
@import 'Components/klaro';
@import 'Overrides/BookingSummaryBar';
@import 'Overrides/BookingSearch';
@import 'Overrides/ImageProgressbar';
@import 'Overrides/CustomProductSelect';
@import 'Overrides/GeoUnitCheckboxAsync';
@import 'Overrides/PackageDetails';
@import 'Overrides/BestPriceLoadingBar';
@import 'Overrides/ThreeColumnListItemLayout';
@import 'Overrides/Filter';
@import 'IBE/Forms';

body {
  font-family: $mainFont;
  font-weight: 300;
}

.modal-open {
  overflow: hidden;
}
