@import './utils/mediaQueries';

.package-list-item {
  border-radius: 1rem;
  box-shadow: $defaultShadow;

  &__origin {
    color: $orange;
  }

  .iso__price-option-panel {
    &__button {
      min-width: 116px;
    }
  }

  &__center {
    svg {
      font-size: $font-size-base * 1.05;
      color: $secondary;
    }

    font-weight: bolder;

    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      position: relative;
      padding-left: 1.5em; /* space to preserve indentation on wrap */
    }

    li:before {
      content: '✓';
      color: $secondary;
      position: absolute;
      left: 0;
    }
  }

  &__room-info {
    line-height: 1.16;
  }

  .iso__three-column-list-item-layout__frame {
    background: #f7f7f7;

    .iso__three-column-list-item-layout__frame__text {
      background: #f7f7f7;
      height: 2rem;

      .primary {
        background: $primary;
        color: $primaryColorContrast;
      }

      .secondary {
        background: $secondary;
        color: $white;
      }

      span {
        font-weight: 700;
        display: block;
        padding: 0.375rem 2rem;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        width: fit-content;
        height: 5rem;
      }
    }
  }

  .card-img-overlay {
    padding: 0.75rem 0.375rem;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    span {
      width: fit-content;
      background: $accentColor;
      color: $accentColorContrast;
      margin: 0 0 5px 5px;
      padding: 0 5px;
      border-radius: 10px;
    }
  }

  &__hotel-logo-container {
    height: auto;
    width: 100px;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  &__hotel-logo {
    height: auto;
    width: 100%;
    @include xlarge {
      border-bottom-left-radius: 0.9rem;
    }
  }

  .included_services-mobile_wrapper {
    .container {
      max-width: 100%;
    }

    .included_services-mobile {
      font-size: 1.4em;
    }
  }

  .iso__three-column-list-item-layout__info {
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: $gray-light;

    @include medium {
      border-top-width: 0;
    }

    &-inner {
      height: auto !important;
    }
  }
}

.package-info {
  padding: 1rem;
  padding-bottom: 0.25rem;
  font-size: smaller;
  font-weight: bolder;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;

  &__package {
    justify-content: end;
  }

  &__details {
    @include small {
      text-align: right;
    }
  }

  &__price {
    font-size: unset;
    font-weight: unset;

    .iso__price__value {
      white-space: nowrap;
    }

    .price__without__discount {
      text-decoration: line-through;
      padding-right: 10px;
      margin-bottom: 0.313rem;
      font-size: 1rem;
    }

    .from__text {
      white-space: nowrap;
      margin-bottom: 0.313rem;
      margin-right: 7px;
      font-size: 1rem;
    }

    .finale__price {
      margin-top: 0.313rem;

      .iso__price__value {
        color: $priceColor;
        font-size: 1.7rem;

        line-height: 1.875rem;
      }
    }
  }
}

.iso__three-column-list-item-layout {
  border-radius: 1rem;
  border: unset;

  &__info-inner > div {
    display: contents !important;
  }

  &__wrapper {
    &__img {
      @include xlarge {
        border-radius: 1rem 0 0 1rem;
      }
      border-radius: 1rem 1rem 0 0;
    }
  }

  &__content {
    background-color: $white;
    padding-bottom: 0.3rem;

    &__headline {
      font-size: unset;
      font-weight: unset;

      .hotelName {
        font-weight: bold;
        font-size: 1.25rem;
      }

      margin-top: 0 !important;
    }
  }

  &__info {
    background-color: $white !important;
    color: $black !important;
    padding: 0 5px !important;
    border-radius: 0 0 1rem 1rem;
    @include xlarge {
      border-radius: 0 1rem 1rem 0;
    }

    @include medium {
      display: flex;
      flex-direction: column;

      > div:first-child {
        flex: 1;
      }
    }

    > div > div {
      &:last-child {
        font-size: $font-size-base * 0.85;
        font-weight: 700;
      }
    }

    > div {
      flex-direction: column !important;

      @include small {
        flex-direction: row !important;
      }

      @include medium {
        flex-direction: column !important;
      }

      > div:first-child {
        flex-grow: initial !important;

        @include medium {
          margin-bottom: 0 !important;
        }
      }

      > div:last-child {
        align-items: flex-start !important;
        margin-bottom: 1rem;

        @include small {
          align-items: flex-end !important;
          margin-bottom: initial;
        }
      }
    }
  }

  &__button {
    margin: 0.5rem 0.5rem;

    .iso__package-item__button {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  .internalFlightInfo {
    background: #ffe5b2 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: 10px;
    margin-top: auto;
    position: relative;
    max-width: 100%;

    &:hover {
      background: #f9d895;
    }

    &__icon {
      vertical-align: middle;
      margin-right: 10px;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
    }

    &__description {
      max-width: 80%;
      z-index: 100;
      position: absolute;
      top: 100%;
      left: 8px;
      margin-top: 4px;
      background-color: white;
      box-shadow: 0px 0px 16px #00000033;
      border-radius: 8px;
      display: inline-block;

      &__content {
        margin: 17px;

        &__header {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    &__description:before {
      content: '';
      position: absolute;
      top: -20px;
      left: 20px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #2e2d2c80 0% 0% no-repeat padding-box;
      z-index: 1000;
    }

    &__popup {
      max-width: 400px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff 0% 0% no-repeat padding-box;
      padding: 17px;
      z-index: 1001;
      box-shadow: 0px 24px 72px #0000001a;
      border-radius: 16px;
      &__content {
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          top: 0;
          &__headerText {
            width: 80%;
            font-weight: bold;
            flex: 1;
            margin-right: 10px;
            white-space: normal;
          }
          &__headerClose {
            padding: 0;
            align-self: flex-start;
            background: transparent;
            border: none;
          }
        }
        &__text {
          padding-top: 7px;
          font-size: 14px;
        }
      }
      @media (max-width: 768px) {
        width: 85%;
      }
    }
  }

  .internal-flight-info-mobile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    .internalFlightInfo {
      height: 25px;
      .internalFlightInfo__text {
        font-size: 14px;
      }
      .internalFlightInfo__icon {
        margin-right: 5px;
      }
    }
  }

  .price-and-flight-info {
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .internalFlightInfo {
      height: 25px;
      .internalFlightInfo__text {
        font-size: 14px;
      }
      .internalFlightInfo__icon {
        margin-right: 5px;
      }
    }
  }
}
