.iso_destinations {
  &-container {
    .iso__price__value {
      color: $priceColor;
      font-size: 16px;
      @include small {
        font-size: 20px;
      }
    }

    .expand_icon {
      color: $primary;
    }

    &__list {
      border-radius: 16px;
      border: solid 1px $lightgray;
    }
  }

  &__empty_results_info {
    color: $primary;
    font-size: 42px;
  }

  &__total-trips {
    color: $primary;
    margin-top: 1rem;
    margin-bottom: 3rem;

    &-counter {
      font-size: 42px;
    }

    &-label {
      font-size: 18px;
      font-weight: bolder;
    }
  }

  &__destination_row {
    cursor: pointer;
    border-bottom: solid 1px $lightgray;
    margin: 0;

    @include small {
      & > div {
        margin: 0 24px;
      }
    }

    .expand_icon {
      margin-left: 0.5rem;
    }

    &-details-row {
      background-color: lighten($secondary, 15%);
      color: $secondaryColorContrast;
      padding: 0.5rem 1rem 0.5rem 1rem;

      @include small {
        padding: 8px 40px;
      }

      &-name {
        font-size: 24px;
      }

      &-price {
        padding-right: 16px !important;
      }
    }

    &-details-row:hover {
      background: darken($secondary, 5%);
    }

    &-details {
      display: none;
    }

    &-name {
      font-size: 16px;
      @include small {
        font-size: 28px;
      }
    }

    &-active {
      cursor: pointer;
      padding: 0 !important;
      .iso_destinations__destination_row-details {
        display: block;
      }

      .iso_destinations__destination_row {
        &-name {
        }
        &-image {
          width: 100%;
          height: 100%;
          border-radius: 16px 16px 0px 0px;

          &_container {
            width: 100%;
            height: 140px;
          }

          &_info {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0.5rem 1rem 0.5rem 1rem;

            @include small {
              margin-left: 24px;
            }
          }
        }
      }

      .iso_destinations__destination_row-image_info {
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
      }

      .expand_icon {
        color: white;
        margin-left: 1rem;
      }
    }
  }
}
