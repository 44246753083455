.iso__date-range-input_text-container {
  display: flex;
  flex-wrap: nowrap;
  font-weight: normal;
  overflow: hidden;

  & > span > span {
    margin-right: 3px;
  }
}

.iso__date-range-input_divider {
  color: $primary;
}

.iso__date-range-input_duration {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iso__date-range-input,
.iso__date-range-input:hover {
  color: initial !important;
  background-color: $white !important;
}

.paxRoomSelection {
  li {
    list-style-type: none;
  }
  position: relative;

  &__rooms {
    border-bottom: 1px solid $gray-400 !important;
  }

  &__item {
    display: block;
  }

  &__spacing__when__mobile {
    display: block;
  }

  &__label {
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 700;
    color: $primary;
    pointer-events: none;
  }

  &__heading {
    color: $primary;
  }

  .pax-rooms-title {
    color: $black;
    font-weight: $font-weight-bold;
  }

  .list-group-item {
    border: none;
    padding: 0.5rem;

    .select {
      &__option {
        &--is-focused,
        &--is-selected {
          background-color: $primary;
        }
      }
    }
  }

  .list-group {
    & > .list-group-item:first-child {
      & .paxRoomSelection__heading {
        color: $black;
        font-weight: normal;
        font-size: medium;
      }
    }
  }

  .dropdown-menu {
    padding: 1.25rem;
    max-width: 100% !important;

    button {
      box-shadow: $confirmationShadow;
    }
  }
}

.productSelect-item {
  padding: 8px 0;
  cursor: pointer;
  padding-left: 10px;

  & > div {
    display: none;
  }
}

.productSelect-country {
  position: relative;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 10px;

  & > div {
    display: block;
    height: 2px;
    left: 10px;
    right: 10px;
    background: $gray-200;
    top: -5px;
    z-index: 2;
    position: absolute;
  }
}

.productSelect-item:hover {
  background-color: rgba($secondary, 0.5);
  color: $white;
}

.iso__custom__product__select {
  font-size: $font-size-sm;

  &__input > span > svg {
    color: $primary;
    font-size: unset;
  }

  &__input .placeholder {
    color: $gray-600;
  }
}

.iso__custom__product__select__lists > div:first-child .productSelect-country {
  margin: 0;

  & > div {
    display: none;
  }
}

.iso__custom__product__select__lists > div:first-child .productSelect-label {
  border-width: 0;
  margin-top: 0;

  & > span {
    display: none;
  }
}

.iso__custom__product__select__dropdown__selection__btn {
  & button {
    font-size: $font-size-sm;
    padding: 0 15px;
  }

  & > button:first-child {
    padding: 0;
  }
}

.iso-destination-checkbox {
  border: 1px solid $secondary;
  background-color: transparent;
  color: $white;
  border-radius: 2px;
  margin-right: 0.5rem;
  display: inline-block;
  padding: 0px;
  width: 1.1em;
  min-width: 1.1em;
  height: 1.1em;
  min-height: 1.1em;

  svg {
    margin: 0px;
    padding: 0px;
    font-weight: 100;
    vertical-align: 0.1em;
    height: fit-content;
  }

  &.checked {
    background-color: $secondary;
  }
}

.iso__custom__product__select__dropdown__selection__item {
  padding: $font-size-base * 0.9;
}

.productSelect-label {
  margin-top: 10px;
  font-weight: bold;
  padding: 5px 10px;
  position: relative;

  & > span {
    display: block;
    height: 2px;
    left: 10px;
    right: 10px;
    background: $gray-400;
    top: -5px;
    z-index: 2;
    position: absolute;
  }
}

.productSelect-item---search {
  padding: 7px 10px;
  cursor: pointer;

  &:hover {
    background-color: $secondary;
    color: $white;
  }
}

.iso__custom__product__select__lists__absolute {
  position: absolute;
  left: 0;
  top: 0;
  right: 35px;
  bottom: 0;
  z-index: 2;
}

.iso__custom__product__select__lists__relative {
  position: relative;
}

.iso__custom__product__select__dropdown {
  max-height: unset;
  left: 20px;
  top: 75px;
  position: absolute;
  z-index: 20;
  box-shadow: 0 0 8px #d7d7d7;
  background: $white;
  overflow: unset;
  padding: 5px;
  right: unset;
  width: 400px;
  max-width: 500px;

  .iso__custom__product__select__lists {
    &-bottom-container {
      padding: 0px 10px 0px 15px;
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        position: fixed;
        bottom: 0;
        width: 100%;
        border: none;
        z-index: 2;
        background: $white;
        padding-right: 25px;
        margin-bottom: 0 !important;
      }
    }

    overflow: auto;
    max-height: 400px;
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      padding-bottom: 75px !important;
    }
  }

  .search-section {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-200;
    box-shadow: $confirmationShadow;
  }

  .iso-magnifying-glass-icon {
    width: 1.1em;
    height: 1.1em;
    align-self: start;
    margin-top: 1em;
  }

  .iso-destination-input-text {
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-color: $gray-600;
    border-radius: 0;
    color: $input-color;
    height: unset;
    padding-right: 25px;
  }

  .iso__custom__product__select__tags {
    margin-top: 5px;

    .iso__custom__product__select__tag {
      display: inline-block;
      width: fit-content;
      margin: 0px 2px;
    }
  }
}

.iso__custom__product__select__lists--empty {
  padding: 10px;
}

.iso__modal-input-wrapper {
  margin: 0;
  max-width: 960px;
  min-height: 100%;
  opacity: 1;
  width: 100%;

  & .modal-content {
    border: 0 !important;
    border-radius: 0.3rem !important;
    min-height: 100vh;
  }

  & .modal-body {
    padding: 5px;
  }

  &--backdrop {
    opacity: 1 !important;
    background: white;
  }

  &--close {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: $font-size-base;
    margin-bottom: $font-size-base * 1.5;
    margin-right: 24px;

    & > svg {
      font-size: $font-size-base * 1.5;
    }
  }

  &--confirmation {
    display: flex;
    justify-content: space-between;
    bottom: 10px;
    position: fixed;
    left: 10px;
    right: 10px;
  }

  & .productSelect-item---search,
  & .productSelect-label {
    padding: 5px 0;
  }

  & .productSelect-item {
    padding-left: 0;
  }

  & .productSelect-country {
    padding-left: 0;

    & > div {
      left: 0;
      right: 0;
    }
  }

  & .productSelect-label > span {
    left: 0;
    right: 0;
  }

  .productSelect-item:hover {
    background-color: $white;
    color: $black;
  }

  .productSelect-item---search {
    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  & input {
    padding-left: 0;
  }

  & .iso__custom__product__select__input {
    padding: 12px;

    & > svg {
      display: none;
    }

    & > span {
      display: block;
    }
  }

  & .iso__custom__product__select__dropdown {
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .iso__custom__product__select__lists {
      padding: 15px;
      overflow: unset;
      max-height: unset;
    }

    .iso__custom__product__select__tags {
      .iso__custom__product__select__tag {
        margin: 3px 6px;
      }
    }
  }
}

.iso__service-list__sorter {
  & div:first-child {
    display: none; // sort by label
  }

  &__icon {
    margin-left: 0.5rem;
  }

  &__button {
    margin-left: 1em !important; // to separate button group
  }

  &__button {
    border: 1px solid $secondary;
    border-radius: 4px !important;
  }

  &__button:hover {
    background-color: darken($secondary, 5%);
    color: $secondaryColorContrast;
  }

  &__button--active {
    background-color: $secondary;
    color: $secondaryColorContrast;
  }
}

.search-collapsible__outer > .search-collapsible__pointer.collapse.show {
  margin-top: 1rem;
  background-color: $green;
  border-radius: 15px;
  padding: 16px;

  .link-button {
    display: none;
  }

  .search-collapsible > div {
    width: 100%;
  }
}

.ibe-search-component .iso__service-search__closeCollapsible {
  display: none !important;
}

.iso__service-search__closeCollapsible {
  justify-content: end;
  display: flex;
  @include medium {
    display: none;
  }
}

.package__search__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  padding: 0.5rem;

  svg {
    margin-right: 1rem;
    font-size: 1.2rem;
  }
}

.iso__service-search__column-headline > .w-100 > label {
  font-size: 12px;
}

.iso__service-search__bar-container {
  display: flex;
  align-items: flex-end;

  .geounit-checkbox,
  .iso__custom__product__select__input {
    .geounit-checkbox__angle,
    .fa-angle-down {
      color: var(--primaryColor) !important;
      margin-right: 5px;
      transition: transform 0.25s ease-in-out;
    }

    &.geounit-checkbox--open .geounit-checkbox__angle,
    &.iso__custom__product__select__input--open .fa-angle-down {
      transform: translateY(0) rotate(180deg);
    }
  }
}

.list-group-item button,
.geounit-checkbox__button-container .btn.btn-secondary {
  color: white !important;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;

  &:hover {
    color: hsla(
      0,
      0%,
      calc(-100 * (calc(var(--primaryColor-l) + -7.5%) - 58.59375%)),
      1
    ) !important;
    background-color: hsla(
      var(--primaryColor-h),
      var(--primaryColor-s),
      calc(var(--primaryColor-l) + -7.5%),
      var(--primaryColor-a, 1)
    ) !important;
    border-color: hsla(
      var(--primaryColor-h),
      var(--primaryColor-s),
      calc(var(--primaryColor-l) + -10%),
      var(--primaryColor-a, 1)
    ) !important;
  }
}
