.feature-list {
  margin-top: 0;
  @include medium {
    margin-top: 106px;
    margin-bottom: 96px;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include medium {
      flex-wrap: nowrap;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    width: 50%;
    text-align: center;
  }

  &__iconBox {
    margin-right: 10px;
    @include medium {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__icon {
    height: 40px;
    width: 40px;
    @include medium {
      height: 100px;
      width: 100px;
    }
  }

  &__text {
    font-size: 18px;
  }
}
