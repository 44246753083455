.link-list {
  & ul,
  & ol {
    padding-left: 1.5rem;
  }
  & .list-inline {
    padding-left: 0;
  }
  & .list-inline > div {
    display: inline-block;
  }
  & .list-inline > div > li {
    display: inline-block;
    margin-left: 0.5rem;
  }
}
