.iso-bestPriceLoadingBar-firstPhase {
  background-color: $accentColor;
}

.iso-bestPriceLoadingBar-firstPhaseEnd {
  background-color: $accentColor;
}

.iso-bestPriceLoadingBar-secondPhase {
  background-color: $accentColor;
}
